/* Sobrescreve as cores padrão do PrimeReact */
.p-row-odd.bg-success {
    background-color: rgb(25 135 84) !important; /* Verde */
    color: #fff !important;
  }
  
  .p-row-odd.bg-danger {
    background-color: #dc3545 !important; /* Vermelho */
    color: #fff !important;
  }
  
  .bg-success {
    background-color: rgb(25 135 84) !important;
    color: #fff !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
    color: #fff !important;
  }

  #cards-aberturas {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  #cards-aberturas .card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    width: 300px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  #cards-aberturas .card h3 {
    margin: 0 0 0.5rem;
  }
  
  #cards-aberturas .card-open {
    border-color: #4caf50;
    background-color: #e8f5e9;
  }
  
  #cards-aberturas .card-closed {
    border-color: #f44336;
    background-color: #ffebee;
  }
  
  #cards-aberturas .card {
    transition: transform 0.2s, box-shadow 0.2s;
}

#cards-aberturas .card:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
