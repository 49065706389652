.layout-container {
  display: flex;
  height: 100vh;
}

.layout-sidebar {
  display: flex;
  position: relative;
  width: 100px;
  background-color: #333;
  background: #fff;
}

.layout-logo {
  padding: 20px;
  text-align: center;
  font-size: 1.5rem;
}

.layout-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  min-height: 100%;
  background: #ffffff;
}

.layout-menu-item {
  width: 100px;
  min-width: 100px;
  min-height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f3f3f3;
  color: #3a3a3a !important;
}

.layout-menu-item i {
  color: #48494b;
  font-size: 32px;
  height: 42px;
  width: 44.78px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-menu-item span {
  text-align: center;
  padding: 0 10px;
  font-size: 13px;
}

.layout-menu-item button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout-box-menus {
  display: flex;
  transition: .5s;
}

.layout-box-menus ul {
  padding: 10px 0;
}

.sub-menu {
  width: 229px;
  min-height: 100px;
  padding: 0 10px;
  margin: 0px;
  border-left: 1px solid #f3f3f3;
  background: #fff;
  box-shadow: 0 3px 30px rgba(0, 0, 0, .1), 0 3px 20px rgba(0, 0, 0, .1);
}

.sub-menu .layout-menu-item {
  white-space: nowrap;
  width: auto;
  min-height: auto;
  border-bottom: 0px;
  font-size: 13px;
  margin-bottom: 10px;
  margin-left: 15px;
  padding: 8px 0;
  height: 35.5px;
}

.sub-menu .layout-menu-item a {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #3a3a3a !important;
}

.sub-menu .layout-menu-item a i {
  font-size: 1.3em;
}

.menu-link {
  text-decoration: none;
  color: #000;
}

.menu-link:hover {
  text-decoration: none; /* Garante que não apareça sublinhado ao passar o mouse */
}
