.login-box-main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.login-box-img-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-box-img-bg img {
  min-width: 100vw;
  min-height: 100vh;
}

.logim-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: white;
  display: flex;
}

.logim-img-box-card {
  width: 340px;
  /* height: 350px; */
  padding: 50px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

.logim-img-box-card img {
  width: 100%;
}

.logim-form {
  padding: 80px;
  min-width: 680px;
}

.logim-form-box-text {
  font-size: 12.8px;
  font-family: "Nunito", sans-serif;
  font-weight: 400px;
  color: #3a3a3a;
  margin-bottom: 24px;
}

.logim-form-box-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.logim-form-box-btn a {
  color: #3a3a3a;
  text-decoration: none;
  font-size: 12.8px;
}

.logim-form .form-group {
  position: relative;
  margin-top: 20px;
}

.logim-form .label-input {
  position: absolute;
  background-color: white;
  padding: 0px 2px;
  top: -5px;
  left: 15px;
  color: #3a3a3ab3;
}
