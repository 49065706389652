.bread-crumb {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  flex-wrap: nowrap;
}

.bread-crumb h1 {
  font-size: 1.25rem;
  display: inline-block;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 10px;
  margin-bottom: 0.5rem;
}

.bread-crumb ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 12px 16px;
  margin-bottom: 8px;
  background-color: initial;
}

.bread-crumb ol li {
  list-style: none;
}

.bread-crumb ol li + li {
  padding-left: 0.5rem;
}

.bread-crumb ol li + li::before {
  content: "|";
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
}

.bread-crumb ol li a {
  color: black;
  text-decoration: none;
  font-size: 0.8rem;
}

.bread-crumb-box hr {
  margin-bottom: 2rem;
}
