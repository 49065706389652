.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group .label-input {
  margin-bottom: 8px;
}

.form-group .select {
  border-radius: 0;
  border: 1px solid #d7d7d7;
  box-shadow: none;
}