.box-bem-vindo {
  width: 100%;
  min-height: 100vh;
  background-image: url("../../../assets/img/akkadian_fundo.e164cd6d.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .box-form {
    background-color: white;
    border-radius: 15px;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1 {
    width: max-content;
  }

  form {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    .campo {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
      }
      .p-datepicker-trigger {
        border-radius: 0;
        font-size: 14px;
        svg {
          font-size: 200px !important;
        }
      }
    }

    button {
      border-radius: 0;
      &.p-button {
        border-radius: 50px;
      }
    }
  }

  .info-cadastro {
    span {
      text-decoration: underline;
      font-weight: bolder;
      padding-bottom: 5px;
    }
    p {
      padding-left: 10px;
      margin: 0;
    }
  }
}
