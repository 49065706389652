.pagination-container {
    margin-top: 1rem;
    text-align: center;
  }
  
  .pagination-container .btn {
    margin: 0 0.25rem;
  }
  
  .pagination-container .btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
  }
  
  .pagination-container .btn-light {
    background-color: #f8f9fa;
    color: black;
  }
  
  .pagination-container .btn-secondary {
    background-color: #6c757d;
    color: white;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center;    /* Centraliza verticalmente, se necessário */
    margin-top: 1rem;       /* Espaçamento entre a tabela e a paginação */
  }
  
  .pagination-container .btn {
    margin: 0 0.25rem; /* Espaçamento entre os botões */
  }
  